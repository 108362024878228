import { useMemo, useState } from "react";
import {
	AllThemes,
	MediaKit,
	MediaKitFacebookBlockPost,
	MediaKitInstagramBlockPost,
	MediaKitPlatformBlock,
	TikTokPost,
	TwitchPost,
	YouTubePost,
} from "@withjuly/fabric";
import { Content } from "./PlatformContent";

export enum METRIC {
	LIKES = "likes",
	COMMENTS = "comments",
	SHARES = "shares",
	SAVES = "saves",
	PLAYS = "plays",
}

interface Post {
	mediaUrl?: string;
}

interface HighlightedPostsProps {
	mediaKit: MediaKit;
	block: MediaKitPlatformBlock;
	theme: AllThemes;
	isPreview?: boolean;
}

export const HighlightedPosts: React.FC<HighlightedPostsProps> = ({
	block,
	theme,
	isPreview,
}) => {
	const [currentPostIndex, setCurrentPostIndex] = useState(0);
	const CurrentPost = useMemo(() => {
		const post = block.fields.recentPosts?.posts?.[currentPostIndex];
		if (!post) {
			return undefined;
		}

		let imageType: string | undefined;
		let mediaUrl: string | undefined;
		let postUrl: string | undefined;
		let metrics: Record<string, number> = {};
		if (block.type === "instagram") {
			const instagramPost = post as MediaKitInstagramBlockPost;
			imageType =
				instagramPost.mediaType === "VIDEO" && !instagramPost.mediaUrl
					? "IMAGE"
					: instagramPost.mediaType;

			mediaUrl = instagramPost.mediaUrl ?? instagramPost.thumbnailUrl ?? "";
			metrics = {
				[METRIC.LIKES]: instagramPost.likeCount,
				[METRIC.COMMENTS]: instagramPost.commentsCount,
			};
			postUrl = instagramPost.postUrl;
		} else if (block.type === "youtube") {
			mediaUrl = (post as YouTubePost).thumbnailUrl;
			metrics = {
				[METRIC.LIKES]: (post as YouTubePost).likeCount ?? 0,
				[METRIC.COMMENTS]: (post as YouTubePost).commentCount ?? 0,
				[METRIC.PLAYS]: (post as YouTubePost).viewCount ?? 0,
			};
			postUrl = `https://www.youtube.com/watch?v=${(post as YouTubePost).id}`;
		} else if (block.type === "tiktok") {
			mediaUrl = (post as TikTokPost).mediaUrl;
			metrics = {
				[METRIC.LIKES]: (post as TikTokPost).likeCount,
				[METRIC.PLAYS]: (post as TikTokPost).viewCount,
			};
			postUrl = (post as TikTokPost).postUrl;
		} else if (block.type === "twitch") {
			mediaUrl = (post as TwitchPost).thumbnailUrl;
			metrics = {
				[METRIC.PLAYS]: (post as TwitchPost).viewCount ?? 0,
			};
			postUrl = (post as TwitchPost).url;
		} else if (block.type === "facebook") {
			const facebookPost = post as MediaKitFacebookBlockPost;
			mediaUrl = facebookPost.mediaUrl;
			metrics = {
				[METRIC.LIKES]: facebookPost.likeCount,
				[METRIC.COMMENTS]: facebookPost.commentCount,
				[METRIC.SHARES]: facebookPost.shareCount,
			};
			postUrl = facebookPost.postUrl;
		}
		if (!mediaUrl) {
			return undefined;
		}

		return (
			<Content
				key={`post-${currentPostIndex}`}
				platform={block.type}
				mediaUrl={mediaUrl}
				url={postUrl ?? ""}
				metrics={metrics}
				type={imageType}
				isHighlight={true}
				theme={theme}
				setPostIndex={setCurrentPostIndex}
				currentIndex={currentPostIndex}
				totalPosts={block.fields.recentPosts.posts.length}
				isPreview={isPreview}
			/>
		);
	}, [currentPostIndex, block, theme, isPreview]);

	if (!CurrentPost) {
		return null;
	}

	return CurrentPost;
};

interface HighlightedPostProps {
	post?: Post;
}

export const HighlightedPost: React.FC<HighlightedPostProps> = ({ post }) => {
	return (
		<div className="relative flex h-full w-full flex-col justify-end">
			<div className="absolute h-full w-full bg-blue-700">
				<img
					src={post?.mediaUrl}
					className="h-full w-full"
					alt="Social media post"
				/>
			</div>
			<div className="w-full bg-black/40 p-4 backdrop-blur-md">Instagram</div>
		</div>
	);
};
