import { AllThemes, MediaKitPlatformBlock } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import {
	Chat,
	ChevronLeft,
	ChevronRight,
	HeartFull,
	Like,
	Play,
} from "@withjuly/julycons";
import React, { ReactElement } from "react";
import { BsShareFill, BsBookFill } from "react-icons/bs";
import { formatNumber } from "~/components/Mediakits/utils";

export enum METRIC {
	LIKES = "likes",
	COMMENTS = "comments",
	SHARES = "shares",
	SAVES = "saves",
	PLAYS = "plays",
}

export const PlatformContent = ({
	block,
	theme,
	isPreview,
}: {
	block: MediaKitPlatformBlock;
	theme: AllThemes;
	isPreview?: boolean;
	isHighlight?: boolean;
}) => {
	if (!block.fields.recentPosts?.enabled || !block.enabled) {
		return null;
	}

	let platformContent: JSX.Element[] | undefined = undefined;

	if (block.type === "facebook") {
		platformContent = block.fields.recentPosts?.posts?.map((post, i) => {
			return (
				<Content
					key={i}
					theme={theme}
					platform={block.type}
					mediaUrl={post.mediaUrl ?? ""}
					url={post.postUrl}
					isPreview={isPreview}
					metrics={{
						[METRIC.LIKES]: post.likeCount,
						[METRIC.COMMENTS]: post.commentCount,
						[METRIC.SHARES]: post.shareCount,
					}}
				/>
			);
		});
	} else if (block.type === "instagram") {
		platformContent = block.fields.recentPosts?.posts?.map((post, i) => {
			return (
				<Content
					key={i}
					theme={theme}
					platform={block.type}
					mediaUrl={post.mediaUrl ?? post.thumbnailUrl ?? ""}
					url={post.postUrl}
					isPreview={isPreview}
					metrics={{
						[METRIC.LIKES]: post.likeCount,
						[METRIC.COMMENTS]: post.commentsCount,
					}}
					type={
						post.mediaType === "VIDEO" && !post.mediaUrl
							? "IMAGE"
							: post.mediaType
					}
				/>
			);
		});
	} else if (block.type === "youtube") {
		platformContent = block.fields.recentPosts?.posts
			?.filter((post) => post.thumbnailUrl !== undefined)
			.map((post, i) => {
				return (
					<Content
						key={i}
						platform={block.type}
						theme={theme}
						mediaUrl={post.thumbnailUrl ?? ""}
						isPreview={isPreview}
						url={`https://www.youtube.com/watch?v=${post.id}`}
						metrics={{
							[METRIC.LIKES]: post.likeCount ?? 0,
							[METRIC.COMMENTS]: post.commentCount ?? 0,
							[METRIC.PLAYS]: post.viewCount ?? 0,
						}}
					/>
				);
			});
	} else if (block.type === "tiktok") {
		platformContent = block.fields.recentPosts?.posts?.map((post, i) => {
			return (
				<Content
					key={i}
					platform={block.type}
					theme={theme}
					mediaUrl={post.mediaUrl}
					isPreview={isPreview}
					url={post.postUrl}
					metrics={{
						[METRIC.LIKES]: post.likeCount ?? 0,
						[METRIC.PLAYS]: post.viewCount ?? 0,
					}}
				/>
			);
		});
	} else if (block.type === "twitch") {
		platformContent = block.fields.recentPosts?.posts?.map((post, i) => {
			return (
				<Content
					key={i}
					platform={block.type}
					theme={theme}
					mediaUrl={post.thumbnailUrl ?? ""}
					isPreview={isPreview}
					url={post.url ?? ""}
					metrics={{
						[METRIC.PLAYS]: post.viewCount ?? 0,
					}}
				/>
			);
		});
	}

	return (
		<div className={cx("flex flex-col", !isPreview && "lg:mr-0")}>
			<div
				className={cx(
					"flex gap-4 overflow-x-scroll pr-4",
					!isPreview && "lg:pr-0",
				)}
			>
				{platformContent ? platformContent : null}
			</div>
		</div>
	);
};

interface ContentProps {
	platform: "instagram" | "tiktok" | "youtube" | "facebook" | "twitch";
	theme: AllThemes;
	url: string;
	mediaUrl: string;
	metrics: {
		[METRIC.LIKES]?: number;
		[METRIC.COMMENTS]?: number;
		[METRIC.PLAYS]?: number;
		[METRIC.SHARES]?: number;
		[METRIC.SAVES]?: number;
	};
	isPreview?: boolean;
	type?: string;
	isHighlight?: boolean;
	setPostIndex?: React.Dispatch<React.SetStateAction<number>>;
	currentIndex?: number;
	totalPosts?: number;
}

export const Content: React.FC<ContentProps> = ({
	platform,
	mediaUrl,
	url,
	metrics,
	type,
	setPostIndex,
	currentIndex,
	totalPosts,
	isHighlight,
	isPreview,
}) => {
	return (
		<a href={url} target="_blank">
			<div
				className={cx(
					"group relative flex aspect-square h-[450px] w-full max-w-[450px] flex-col justify-end overflow-hidden rounded-2xl bg-cover md:h-[560px] md:w-[560px]",
					platform === "instagram" &&
						!isHighlight &&
						type === "VIDEO" &&
						"h-[450px] max-w-[256px] md:h-[560px] md:max-w-[352px]",
					platform === "youtube" &&
						!isHighlight &&
						"h-[224px] max-w-[320px] md:h-[360px] md:w-[640px]",
					platform === "twitch" &&
						!isHighlight &&
						"h-[180px] w-[320px] md:h-[253px] md:w-[450px]",
					platform === "tiktok" &&
						!isHighlight &&
						"h-[450px] max-w-[256px] md:h-[560px] md:max-w-[352px]",
					platform === "facebook" && !isHighlight && "h-[450px] w-[450px]",
					isHighlight &&
						"h-[448px] max-h-[448px] w-[303px] max-w-[303px] lg:h-[520px] lg:max-h-[520px] lg:w-[352px] lg:max-w-[352px]",
					isHighlight &&
						isPreview &&
						"lg:h-[448px] lg:max-h-[448px] lg:w-[303px] lg:max-w-[303px]",
				)}
			>
				{type === "VIDEO" && mediaUrl.includes(".mp4") ? (
					<video
						src={mediaUrl}
						className="h-full w-full object-cover transition-all group-hover:scale-105"
						autoPlay
						muted
						loop
						controls={false}
					/>
				) : (
					<img
						className={cx(
							"absolute flex aspect-square h-[450px] w-full max-w-[450px] flex-col justify-end overflow-hidden rounded-b-3xl rounded-t-2xl bg-cover object-cover object-center transition-all group-hover:scale-105 md:h-[560px] md:w-[560px]",
							platform === "youtube" &&
								!isHighlight &&
								"h-[224px] max-w-[320px] md:h-[360px] md:w-[640px]",
							platform === "tiktok" &&
								!isHighlight &&
								"h-[450px] max-w-[256px] md:h-[560px] md:max-w-[352px]",
							platform === "twitch" &&
								!isHighlight &&
								"h-[180px] w-[320px] md:h-[253px] md:w-[450px]",
							isHighlight &&
								"h-[448px] max-h-[448px] w-[303px] max-w-[303px] lg:h-[520px] lg:max-h-[520px] lg:w-[352px] lg:max-w-[352px]",
							isHighlight && isPreview && "lg:max-h-[448px] lg:max-w-[303px]",
						)}
						src={mediaUrl}
						alt=""
					/>
				)}
				<div
					className={cx(
						"absolute z-10 h-full w-full bg-gradient-to-t from-gray-900/20 to-transparent transition-all group-hover:opacity-0 dark:from-gray-900/80",
					)}
				></div>
				<div
					onClick={(e) => {
						e.preventDefault();
					}}
					className={cx(
						"z-20 flex w-full flex-row items-center justify-between rounded-b-2xl bg-black/40 p-4 backdrop-blur-md",
					)}
				>
					<div className="flex items-center gap-4">
						{Object.keys(metrics).map((key, i) => {
							if (metrics[key as METRIC] !== 0) {
								return (
									<ContentMetric
										key={i}
										platform={platform}
										type={key as METRIC}
										value={metrics[key as METRIC] ?? 0}
									/>
								);
							}
						})}
					</div>
					{setPostIndex ? (
						<div className="flex items-center gap-3">
							<button
								onClick={(e) => {
									e.preventDefault();
									setPostIndex?.((prev) => prev - 1);
								}}
								disabled={currentIndex === 0}
								className={cx(
									"bg-white-100 flex h-6 w-6 items-center justify-center rounded-full opacity-75 transition-colors hover:opacity-100",
									currentIndex === 0 &&
										"cursor-not-allowed opacity-25 hover:opacity-25",
								)}
							>
								<ChevronLeft className="w-3 pr-[1px] text-gray-900" />
							</button>
							<button
								onClick={(e) => {
									e.preventDefault();
									setPostIndex?.((prev) => prev + 1);
								}}
								disabled={currentIndex === (totalPosts ?? 0) - 1}
								className={cx(
									"bg-white-100 flex h-6 w-6 items-center justify-center rounded-full opacity-75 transition-colors hover:opacity-100",
									currentIndex === (totalPosts ?? 0) - 1 &&
										"cursor-not-allowed opacity-25 hover:opacity-25",
								)}
							>
								<ChevronRight className="w-3 pl-[1px] text-gray-900" />
							</button>
						</div>
					) : null}
				</div>
			</div>
		</a>
	);
};

interface ContentMetricProps {
	type: METRIC;
	value: number;
	platform: "instagram" | "tiktok" | "youtube" | "twitch" | "facebook";
}

const ContentMetric: React.FC<ContentMetricProps> = ({
	type,
	platform,
	value,
}) => {
	let icon: ReactElement | null = <HeartFull className="h-auto w-4" />;

	if (type === "likes") {
		if (platform === "youtube") {
			icon = <Like className="h-auto w-4" />;
		}
	} else if (type === "comments") {
		icon = <Chat className="h-auto w-4" />;
	} else if (type === "shares") {
		icon = <BsShareFill className="h-auto w-4" />;
	} else if (type === "plays") {
		if (platform === "youtube") {
			icon = null;
		} else {
			icon = <Play className="h-auto w-4" />;
		}
	} else if (type === "saves") {
		icon = <BsBookFill className="h-auto w-4" />;
	}

	return (
		<div className="flex flex-row items-center justify-center gap-1">
			{icon ? <div>{icon}</div> : null}
			<div className="flex items-end gap-1">
				<p className="text-body-xl">{formatNumber(value)}</p>
				{type === "plays" && platform === "youtube" ? <p>views</p> : null}
			</div>
		</div>
	);
};
