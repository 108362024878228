import { MediaKitStat } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieStat = ({
	theme,
	stat,
	isPreview,
	platform,
}: {
	theme: string;
	stat: MediaKitStat & { type: "pie" };
	isPreview?: boolean;
	platform: string;
}) => {
	const sortedByValue = Object.entries(stat.data).sort((a, b) => b[1] - a[1]);
	const [graphColorPrimary, setGraphColorPrimary] = useState(() => "");
	const [graphColorSecondary, setGraphColorSecondary] = useState(() => "");

	const toDisplay = sortedByValue.slice(0, 3);
	if (sortedByValue.length > 3) {
		const otherSum = sortedByValue
			.slice(3)
			.map(([, value]) => value)
			.reduce((a, b) => a + b, 0);

		toDisplay.push(["Other", otherSum]);
	}

	const total = sortedByValue.reduce((a, [, value]) => a + value, 0) || 1;

	useEffect(() => {
		const primary = window
			.getComputedStyle(document.documentElement)
			.getPropertyValue("--theme-graph-primary");
		const secondary = window
			.getComputedStyle(document.documentElement)
			.getPropertyValue("--theme-graph-secondary");
		setGraphColorPrimary(() => primary);
		setGraphColorSecondary(() => secondary);
	}, [theme]);

	if (sortedByValue.length === 0) {
		return null;
	}

	return (
		<div
			className={cx(
				"bg-theme-fill border-theme-stroke col-start-1 col-end-1 flex h-[324px] w-full flex-col gap-4 rounded-2xl border px-8 pb-8 pt-4 backdrop-blur-[125px]",
				!isPreview && "lg:col-end-3 lg:pb-8 lg:pt-6",
			)}
		>
			<p className="text-body-xl text-theme-text-secondary text-justify">
				{stat.title}
			</p>
			<div
				className={cx(
					"flex h-[230px] flex-col items-center justify-center gap-4 lg:flex-row",
					isPreview && "lg:flex-col",
				)}
			>
				<div
					className={cx(
						"flex min-h-[144px] min-w-[144px] items-center justify-center lg:min-h-[180px] lg:min-w-[180px]",
						!isPreview && "lg:min-h-[180px] lg:min-w-[180px]",
					)}
				>
					<Doughnut
						id="barstat"
						width="100%"
						options={{ cutout: "75%", events: [] }}
						data={{
							labels: [],
							datasets: [
								{
									data: toDisplay.map(([, value]) => value),
									backgroundColor: toDisplay.map((_, i) => {
										if (i === 0) {
											return `rgb(${graphColorPrimary})`;
										} else {
											return `rgb(${graphColorSecondary})`;
										}
									}),
									borderWidth: 2,
									borderColor: "#00000000",
								},
							],
						}}
					/>
				</div>
				<div
					className={cx(
						"group flex w-1/2 flex-col justify-between gap-4 lg:w-auto",
					)}
				>
					{toDisplay.map(([label, value], i) => (
						<div
							key={`${platform}-${label}-${i}`}
							className={cx("group flex w-full flex-col gap-4")}
						>
							<div
								className={cx(
									"flex w-full items-center justify-between gap-6",
									isPreview && "lg:justify-start",
								)}
							>
								<div className="flex flex-row items-center gap-2">
									<span className="group-first:bg-theme-graph-primary bg-theme-graph-secondary aspect-square h-3 w-3 rounded-sm" />
									<p
										className={cx(
											"text-theme-text-primary w-10 lg:w-full",
											isPreview && "lg:w-10",
										)}
									>
										{label}
									</p>
								</div>
								<p className="text-theme-text-primary">
									{Math.round((value / total) * 100)}%
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
