import {
	AllThemes,
	MediaKitValueStat,
	MediaKitFacebookBlock,
	formatFollowerCount,
	MediaKitStat,
} from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import {
	Chart,
	Chat,
	ExternalLink,
	HeartFull,
	JulyLogo,
	User,
	Eye,
	FacebookLogo,
	Forward,
} from "@withjuly/julycons";
import { PlatformContent } from "./PlatformContent";
import { StatCard } from "./StatCard";
import { ChatCircleDots, UsersThree } from "@withjuly/julycons/bold";
import { BarStat } from "./BarStat";
import { TimeBetween } from "~/components/Utility/Time/TimeBetween";
import { useEffect, useMemo, useState } from "react";
import { DateRange } from "./InstagramStats";

interface FacebookStatsProps {
	blocks: MediaKitFacebookBlock[];
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
	darkMode: boolean;
	showDateRangeFilters: boolean;
}

export const FacebookStats: React.FC<FacebookStatsProps> = ({
	blocks,
	theme,
	isPreview,
	isMobilePreview,
	darkMode,
	showDateRangeFilters,
}) => {
	blocks.sort((a, b) => {
		const aSubs =
			(
				a.fields.stats.find((stat) => stat.name === "followers") as
					| MediaKitValueStat
					| undefined
			)?.value ?? 0;

		const bSubs =
			(
				b.fields.stats.find((stat) => stat.name === "followers") as
					| MediaKitValueStat
					| undefined
			)?.value ?? 0;

		return bSubs - aSubs;
	});

	return (
		<div className="flex flex-col gap-32">
			{blocks.map((block, i) => (
				<OneFacebookStats
					key={`facebook-stats-${i}`}
					indexText={
						blocks.length > 1 ? `${i + 1} of ${blocks.length} pages` : undefined
					}
					block={block}
					theme={theme}
					isPreview={isPreview}
					isMobilePreview={isMobilePreview}
					darkMode={darkMode}
					showDateRangeFilters={showDateRangeFilters}
				/>
			))}
		</div>
	);
};

interface OneFacebookStatsProps {
	block: MediaKitFacebookBlock;
	indexText?: string;
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
	darkMode: boolean;
	showDateRangeFilters: boolean;
}

export const OneFacebookStats: React.FC<OneFacebookStatsProps> = ({
	block,
	indexText,
	theme,
	isPreview,
	isMobilePreview,
	showDateRangeFilters,
}) => {
	const [selectedRange, setSelectedRange] = useState<DateRange>(
		DateRange.month,
	);
	const displayStats = useMemo(() => {
		if (!block.areTabsVisible) {
			return block.fields.stats;
		} else if (selectedRange === DateRange.week) {
			return block.fields.weekStats ?? [];
		} else if (selectedRange === DateRange.twoWeek) {
			return block.fields.twoWeekStats ?? [];
		} else if (selectedRange === DateRange.month) {
			return block.fields.monthStats ?? [];
		}
		return block.fields.stats;
	}, [
		block.areTabsVisible,
		block.fields.monthStats,
		block.fields.stats,
		block.fields.twoWeekStats,
		block.fields.weekStats,
		selectedRange,
	]);

	useEffect(() => {
		if (showDateRangeFilters === false) {
			setSelectedRange(DateRange.month);
		}
	}, [showDateRangeFilters]);

	const countryStat = displayStats.find((stat) => stat.name === "country") as
		| (MediaKitStat & { type: "bar" })
		| undefined;

	const getIcon = (name: string) => {
		let Icon = User;
		if (name === "engagement_rate") {
			Icon = Chart;
		} else if (name === "average_likes" || name === "page_likes") {
			Icon = HeartFull;
		} else if (name === "average_comments") {
			Icon = Chat;
		} else if (name === "impressions") {
			Icon = Eye;
		} else if (name === "engaged_users") {
			Icon = UsersThree;
		} else if (name === "average_reactions") {
			Icon = ChatCircleDots;
		} else if (name === "average_shares") {
			Icon = Forward;
		}
		return Icon;
	};

	const dateRangeTabs = (isMobile: boolean) => {
		return (
			<div className="flex w-full gap-2">
				<button
					onClick={() => setSelectedRange(DateRange.twoMonth)}
					className={cx(
						"text-body-xl flex h-7 items-center justify-center rounded-xl p-[10px]",
						selectedRange === DateRange.twoMonth
							? "bg-theme-button-fill text-theme-text-button"
							: "bg-theme-button-fill-secondary text-theme-text-primary",
						isMobile && "w-full",
					)}
				>
					{isMobile ? "" : "Past"} 60d
				</button>
				<button
					onClick={() => setSelectedRange(DateRange.month)}
					className={cx(
						"text-body-xl flex h-7 items-center justify-center rounded-xl p-[10px]",
						selectedRange === DateRange.month
							? "bg-theme-button-fill text-theme-text-button"
							: "bg-theme-button-fill-secondary text-theme-text-primary",
						isMobile && "w-full",
					)}
				>
					{isMobile ? "" : "Past"} 30d
				</button>
				<button
					onClick={() => setSelectedRange(DateRange.twoWeek)}
					className={cx(
						"text-body-xl flex h-7 items-center justify-center rounded-xl p-[10px]",
						selectedRange === "14d"
							? "bg-theme-button-fill text-theme-text-button"
							: "bg-theme-button-fill-secondary text-theme-text-primary",
						isMobile && "w-full",
					)}
				>
					{isMobile ? "" : "Past"} 14d
				</button>
				<button
					onClick={() => setSelectedRange(DateRange.week)}
					className={cx(
						"text-body-xl flex h-7 items-center justify-center rounded-xl p-[10px]",
						selectedRange === "7d"
							? "bg-theme-button-fill text-theme-text-button"
							: "bg-theme-button-fill-secondary text-theme-text-primary",
						isMobile && "w-full",
					)}
				>
					{isMobile ? "" : "Past"} 7d
				</button>
			</div>
		);
	};

	const updatedAt = displayStats.reduce((acc, stat) => {
		if (stat.type === "value") {
			acc.push(stat);
		}
		return acc;
	}, [] as MediaKitValueStat[])[0]?.updatedAt;

	return (
		<div className="animate-open-fade-move-down flex h-full w-full flex-col gap-16">
			<div className="flex flex-col gap-4">
				<div className="flex items-end justify-between gap-3 ">
					<a
						href={block.fields.accountUrl}
						target="_blank"
						className="flex items-center gap-4"
					>
						<FacebookLogo className="h-8 w-8" />
						<div className="flex flex-col">
							<p className="text-theme-text-primary text-xl font-semibold">
								{block.fields.username}
							</p>
							{indexText ? (
								<p className="text-theme-text-secondary">{indexText}</p>
							) : null}
						</div>
					</a>

					<a
						href={block.fields.accountUrl}
						target="_blank"
						rel="noreferrer"
						className="text-theme-text-secondary flex items-center gap-1 pb-[3px] font-medium"
					>
						Go to profile <ExternalLink />
					</a>
				</div>
				<div>
					<PlatformContent block={block} isPreview={isPreview} theme={theme} />
				</div>
			</div>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="mb-2 flex w-full items-center justify-between">
					<div className="flex items-center gap-6">
						<p className="text-theme-text-primary text-xl font-semibold leading-[1.30em]">
							Facebook Stats
						</p>
						{block.areTabsVisible && showDateRangeFilters ? (
							<div className={cx("hidden lg:flex", isPreview && "lg:hidden")}>
								{dateRangeTabs(false)}
							</div>
						) : null}
					</div>
					{updatedAt ? (
						<div className="flex items-center gap-2">
							<p className="text-theme-text-primary text-xs font-semibold leading-[1.30em] opacity-50">
								Updated{" "}
								<TimeBetween
									from={updatedAt}
									isMobilePreview={isMobilePreview}
								/>{" "}
								by
							</p>
							<JulyLogo className="text-theme-text-primary h-4 w-auto" />
						</div>
					) : null}
				</div>
				{block.areTabsVisible && showDateRangeFilters ? (
					<div className={cx("mb-2 flex lg:hidden", isPreview && "lg:flex")}>
						{dateRangeTabs(true)}
					</div>
				) : null}
				<div
					className={cx(
						"flex h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4 lg:hidden lg:flex-nowrap lg:gap-4",
						isPreview &&
							!isMobilePreview &&
							"lg:flex lg:flex-wrap lg:gap-0 lg:gap-y-4",
						isMobilePreview && "w-full flex-col flex-nowrap",
					)}
				>
					{displayStats
						.filter((stat) => stat.enabled)
						.map((stat, i) => {
							if (stat.type !== "value") {
								return null;
							} else {
								const Icon = getIcon(stat.name);

								let value = formatFollowerCount(Math.round(stat.value));
								if (stat.display === "percent") {
									value = (stat.value / 100).toLocaleString("en-US", {
										style: "percent",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									});
								}

								return (
									<div
										key={`facebook-stat-${i}`}
										className={cx(
											"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:odd:last:pr-0 md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
											isPreview &&
												!isMobilePreview &&
												"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
											isMobilePreview &&
												"w-full basis-auto md:odd:pr-0 md:even:pl-0",
											!isPreview && "lg:basis-auto",
										)}
									>
										<StatCard
											title={stat.title}
											value={value}
											icon={
												<Icon className="text-theme-text-secondary h-5 w-5" />
											}
											isDetailsEnabled={stat.source !== "custom"}
											stat={stat}
										/>
									</div>
								);
							}
						})}
				</div>
				<div
					className={cx(
						"hidden h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4 lg:flex lg:flex-nowrap lg:gap-4",
						isPreview && !isMobilePreview && "md:hidden lg:hidden",
						isMobilePreview && "w-full flex-col flex-nowrap",
					)}
				>
					{displayStats
						.filter(
							(stat) =>
								["followers", "page_likes", "engagement_rate"].includes(
									stat.name,
								) && stat.enabled,
						)
						.map((stat, i) => {
							if (stat.type !== "value") {
								return null;
							} else {
								const Icon = getIcon(stat.name);

								let value = formatFollowerCount(Math.round(stat.value));
								if (stat.display === "percent") {
									value = (stat.value / 100).toLocaleString("en-US", {
										style: "percent",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									});
								}

								return (
									<div
										key={`facebook-stat-${i}`}
										className={cx(
											"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
											isPreview &&
												!isMobilePreview &&
												"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
											isMobilePreview &&
												"w-full basis-auto md:odd:pr-0 md:even:pl-0",
											!isPreview && "lg:basis-auto",
										)}
									>
										<StatCard
											key={`facebook-stat-${i}`}
											title={stat.title}
											value={value}
											icon={
												<Icon className="text-theme-text-secondary h-5 w-5" />
											}
											isDetailsEnabled={stat.source !== "custom"}
											stat={stat}
										/>
									</div>
								);
							}
						})}
				</div>

				<div
					className={cx(
						"hidden h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4 lg:flex lg:flex-nowrap lg:gap-4",
						isPreview && !isMobilePreview && " md:hidden lg:hidden",
						isMobilePreview && "w-full flex-col flex-nowrap",
					)}
				>
					{displayStats
						.filter(
							(stat) =>
								[
									"average_likes",
									"average_reactions",
									"average_shares",
								].includes(stat.name) && stat.enabled,
						)
						.map((stat, i) => {
							if (stat.type !== "value") {
								return null;
							} else {
								const Icon = getIcon(stat.name);

								let value = formatFollowerCount(Math.round(stat.value));
								if (stat.display === "percent") {
									value = (stat.value / 100).toLocaleString("en-US", {
										style: "percent",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									});
								}

								return (
									<div
										key={`facebook-stat-${i}`}
										className={cx(
											"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
											isPreview &&
												!isMobilePreview &&
												"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
											isMobilePreview &&
												"w-full basis-auto md:odd:pr-0 md:even:pl-0",
											!isPreview && "lg:basis-auto",
										)}
									>
										<StatCard
											key={`facebook-stat-${i}`}
											title={stat.title}
											value={value}
											icon={
												<Icon className="text-theme-text-secondary h-5 w-5" />
											}
											isDetailsEnabled={stat.source !== "custom"}
											stat={stat}
										/>
									</div>
								);
							}
						})}
				</div>
				<div
					className={cx(
						"hidden h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4 lg:flex lg:flex-nowrap lg:gap-4",
						isPreview && !isMobilePreview && " md:hidden lg:hidden",
						isMobilePreview && "w-full flex-col flex-nowrap",
					)}
				>
					{displayStats
						.filter(
							(stat) =>
								["average_comments", "impressions"].includes(stat.name) &&
								stat.enabled,
						)
						.map((stat, i) => {
							if (stat.type !== "value") {
								return null;
							} else {
								const Icon = getIcon(stat.name);

								let value = formatFollowerCount(Math.round(stat.value));
								if (stat.display === "percent") {
									value = (stat.value / 100).toLocaleString("en-US", {
										style: "percent",
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									});
								}

								return (
									<div
										key={`facebook-stat-${i}`}
										className={cx(
											"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
											isPreview &&
												!isMobilePreview &&
												"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
											isMobilePreview &&
												"w-full basis-auto md:odd:pr-0 md:even:pl-0",
											!isPreview && "lg:basis-auto",
										)}
									>
										<StatCard
											key={`facebook-stat-${i}`}
											title={stat.title}
											value={value}
											icon={
												<Icon className="text-theme-text-secondary h-5 w-5" />
											}
											isDetailsEnabled={stat.source !== "custom"}
											stat={stat}
										/>
									</div>
								);
							}
						})}
				</div>
				<div
					className={cx(
						"flex h-fit flex-wrap items-center gap-4 md:gap-0 md:gap-y-4 lg:flex-nowrap lg:gap-4",
						isPreview &&
							!isMobilePreview &&
							"lg:flex lg:flex-wrap lg:gap-0 lg:gap-y-4",
						isMobilePreview && "w-full flex-col flex-nowrap",
					)}
				>
					{block.fields.customStats
						?.filter((stat) => stat.enabled && stat.type === "custom")
						.map((stat, i) => {
							return (
								<div
									key={`facebook-stat-${i}`}
									className={cx(
										"w-full md:basis-1/2 md:odd:pr-2 md:odd:last:basis-full md:odd:last:pr-0 md:even:pl-2 lg:p-0 lg:odd:pr-0 lg:even:pl-0",
										isPreview &&
											!isMobilePreview &&
											"lg:odd:pr-2 lg:odd:last:p-0 lg:even:pl-2",
										isMobilePreview &&
											"w-full basis-auto md:odd:pr-0 md:even:pl-0",
										!isPreview && "lg:basis-auto",
									)}
								>
									<StatCard
										title={stat.title}
										value={stat.value.toString()}
										isDetailsEnabled={true}
										stat={stat}
									/>
								</div>
							);
						})}
				</div>
				<div
					className={cx(
						"flex flex-col flex-wrap-reverse items-start gap-y-4 md:flex-row lg:flex-nowrap lg:gap-4",
						isPreview && "lg:flex-wrap-reverse lg:gap-0 lg:gap-y-4",
						isMobilePreview && "md:flex-wrap-reverse lg:flex-wrap-reverse",
					)}
				>
					{countryStat && countryStat.enabled ? (
						<div
							className={cx(
								"w-full md:basis-full lg:basis-auto",
								isPreview && "md:basis-full lg:basis-full",
							)}
						>
							<BarStat
								isPreview={isPreview}
								stat={countryStat}
								sortBy="value"
								demographic="location"
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};
