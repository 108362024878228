import { MediaKitStat } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";

export const BarStat = ({
	stat,
	isPreview,
	truncate = true,
	sortBy = "key",
	truncateKey = "Other",
	demographic,
}: {
	stat: MediaKitStat & { type: "bar" };
	isPreview?: boolean;
	truncate?: boolean;
	sortBy?: "key" | "value";
	truncateKey?: string;
	demographic: "age" | "location";
}) => {
	const total = Object.values(stat.data).reduce((a, b) => a + b, 0) || 1;
	let sortedData = Object.entries(stat.data)
		.sort((a, b) => {
			if (sortBy === "key") {
				return a[0].localeCompare(b[0]);
			} else {
				return b[1] - a[1];
			}
		})
		.map(([key, value]) => {
			if (key.toLowerCase() === "united kingdom") {
				return ["UK", value] as const;
			} else {
				return [key, value] as const;
			}
		});

	if (truncate) {
		const toDisplay = sortedData.slice(0, 4);
		if (sortedData.length > 3) {
			const otherSum = sortedData
				.slice(4)
				.map(([, value]) => value)
				.reduce((a, b) => a + b, 0);

			toDisplay.push([truncateKey, otherSum]);
		}

		sortedData = toDisplay;
	}

	return (
		<div
			className={cx(
				"bg-theme-fill border-theme-stroke col-start-1 col-end-1 flex h-[324px] w-full flex-col gap-8 rounded-2xl border px-8 pb-8 pt-4",
				!isPreview && "lg:col-end-3 lg:pb-10 lg:pt-6",
			)}
		>
			<p className="text-body-xl text-theme-text-secondary text-justify">
				{stat.title}
			</p>
			<div className="flex h-full w-full flex-col justify-center gap-6">
				{sortedData.map(([key, value], i) => (
					<div key={i} className="flex items-center justify-between">
						<p
							className={cx(
								"text-body-xl text-theme-text-primary w-fit min-w-[96px] whitespace-nowrap text-left",
								demographic === "age" && "min-w-[64px]",
							)}
						>
							{keyToDisplay(key)}
						</p>
						<div className="bg-theme-graph-secondary relative flex h-4 w-full overflow-hidden rounded-[6px]">
							<span
								className="bg-theme-graph-primary absolute left-0 h-full rounded"
								style={{
									width: `${(value / total) * 100}%`,
								}}
							/>
						</div>
						<p className="text-body-xl text-theme-text-primary ml-3 w-fit min-w-[48px] whitespace-nowrap text-right">
							{((value / total) * 100).toFixed(1)}%
						</p>
					</div>
				))}
			</div>
		</div>
	);
};

const keyToDisplay = (key: string): string => {
	const keyLowerCase = key.toLowerCase();
	if (keyLowerCase === "united kingdom") {
		return "UK";
	} else if (keyLowerCase === "united states") {
		return "USA";
	} else if (keyLowerCase === "united arab emirates") {
		return "UAE";
	} else if (keyLowerCase === "new zealand") {
		return "NZ";
	} else if (keyLowerCase === "south africa") {
		return "SA";
	} else if (keyLowerCase === "Republic of Ireland") {
		return "Ireland";
	} else {
		return key;
	}
};
