import { MediaKitRatesBlock, AllThemes } from "@withjuly/fabric";
import { cx } from "@withjuly/frontend-common";
import { Email } from "@withjuly/julycons";
import React from "react";

interface RatesProps {
	name: string;
	contact: string;
	block: MediaKitRatesBlock;
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
	currency: string;
}

export const Rates: React.FC<RatesProps> = ({
	name,
	contact,
	block,
	theme,
	isPreview,
	isMobilePreview,
	currency,
}) => {
	return (
		<div className="animate-open-fade-move-down flex flex-wrap">
			{block.rates.map((rate, i) => (
				<Rate
					key={`rate-${i}`}
					name={name}
					contact={contact}
					index={i}
					{...rate}
					theme={theme}
					isPreview={isPreview}
					isMobilePreview={isMobilePreview}
					currency={currency}
				/>
			))}
		</div>
	);
};

interface RateProps {
	name: string;
	contact: string;
	title: string;
	price: string;
	description?: string;
	index: number;
	theme: AllThemes;
	isPreview?: boolean;
	isMobilePreview?: boolean;
	currency: string;
}

const Rate: React.FC<RateProps> = ({
	name,
	contact,
	title,
	price,
	description,
	isPreview,
	isMobilePreview,
	currency,
}) => {
	const displayPrice = () => {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: currency,
		});
		return formatter.format(parseFloat(price));
	};
	const body = `Hey ${name}!

I just saw your MediaKit and think your ${title} plan is exactly what we need for our new campaign!

Do you have any ideas for what we could do together? I'd love to hear them!

Thanks!`;

	return (
		<a
			className={cx(
				"w-full transform p-2 transition-transform hover:scale-[1.02] md:basis-full md:odd:last:basis-full lg:basis-1/2",
				isPreview && !isMobilePreview && "lg:basis-full",
				isMobilePreview && "basis-full px-0 md:basis-full lg:basis-full",
			)}
			href={`mailto:${contact}?subject=I loved your MediaKit!&body=${encodeURIComponent(
				body,
			)}`}
		>
			<div className="bg-theme-fill flex w-full flex-col gap-2 rounded-2xl py-6 pl-8 pr-6 backdrop-blur-[125px] ">
				<div className="flex flex-col gap-1">
					<div className="flex w-full items-center justify-between">
						<p className="text-body-xl text-theme-text-secondary max-w-[90%] truncate">
							{title}
						</p>
						<Email className="text-theme-text-primary" />
					</div>{" "}
					<p className="text-theme-text-primary text-2xl font-semibold leading-[1.30em]">
						{displayPrice()}
					</p>
				</div>
				<p className="text-body-xl text-theme-text-primary">{description}</p>
			</div>
		</a>
	);
};
