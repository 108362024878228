export const getFrontendDomain = () => {
	if (typeof window === "undefined") {
		return "app.withjuly.com";
	}

	if (location.host.startsWith("localhost")) {
		return "localhost:3000";
	} else if (
		location.host.startsWith("main.withjanuary.com") ||
		location.host.startsWith("main.july.bio")
	) {
		return "main.withjanuary.com";
	}
	return "app.withjuly.com";
};

export const getBioDomain = () => {
	if (typeof window === "undefined") {
		return "july.bio";
	}

	if (location.host.startsWith("localhost")) {
		return "localhost:3000/kit";
	} else if (
		location.host.startsWith("main.withjanuary.com") ||
		location.host.startsWith("main.july.bio")
	) {
		return "main.july.bio";
	}

	return "july.bio";
};
