import {
	MediaKitBlock,
	PlatformSizeClass,
	PlatformSizeClassSchema,
} from "@withjuly/fabric";

export const getBlocksOfType = <T>(blocks: MediaKitBlock[], type: string) => {
	const blocksOfType = blocks.filter((block) => block.type === type);
	blocksOfType.sort((a, b) => a.order - b.order);
	const hasEnabledBlock =
		blocksOfType.length > 0 && blocksOfType.some((block) => block.enabled);
	const lowestOrderBlock = blocksOfType[0]?.order;

	return {
		blocks: blocksOfType as T[],
		enabled: hasEnabledBlock,
		lowestOrder: lowestOrderBlock ?? 0,
		type,
	};
};

const sizeToClass = (size: number): PlatformSizeClass => {
	if (size >= 1000000) {
		return PlatformSizeClassSchema.Enum.Premium;
	} else if (size >= 500000) {
		return PlatformSizeClassSchema.Enum.Mega;
	} else if (size >= 150000) {
		return PlatformSizeClassSchema.Enum.Macro;
	} else if (size >= 50000) {
		return PlatformSizeClassSchema.Enum.Mid;
	} else if (size >= 10000) {
		return PlatformSizeClassSchema.Enum.Micro;
	} else {
		return PlatformSizeClassSchema.Enum.Nano;
	}
};

export const getEngagementRateThresholdValue = (
	followers: number,
	platform: "instagram" | "tiktok" | "youtube",
) => {
	const creatorClass = sizeToClass(followers);
	return thresholdValues[platform][creatorClass];
};

const thresholdValues = {
	instagram: {
		Nano: 5,
		Micro: 2.5,
		Mid: 2,
		Macro: 1.75,
		Mega: 1.5,
		Premium: 1.35,
	},
	tiktok: {
		Nano: 15,
		Micro: 10,
		Mid: 8,
		Macro: 7,
		Mega: 1.5,
		Premium: 1.35,
	},
	youtube: {
		Nano: 2,
		Micro: 1,
		Mid: 0.5,
		Macro: 0.5,
		Mega: 0.5,
		Premium: 0.5,
	},
};
